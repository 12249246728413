"use client";

import { DropdownData, Navigation } from "@layout/navigation/Nav.types";
import { screens } from "@tailwind/screens";
import { cn } from "@utils/tailwind";
import { head, isEmpty } from "lodash";
import { FC, MouseEventHandler, useEffect, useRef, useState } from "react";
import {
  buildDropdowns,
  buildDropdownsMap,
  changeColorsLogic,
  getOpenDropdowns,
  setScrollEnabled,
  useOnEscape,
  useSearchShortcut,
} from "./Nav.helpers";
import { Backdrop } from "./components/backdrop/Backdrop";
import { ContactButton } from "./components/contact-button/ContactButton";
import { DropdownColumns } from "./components/dropdown-columns/DropdownColumns";
import { DropdownColumnsAndRelated } from "./components/dropdown-columns-and-related/DropdownColumnsAndRelated";
import { DropdownColumnsDescriptive } from "./components/dropdown-columns-descriptive/DropdownColumnsDescriptive";
import { DropdownContainer } from "./components/dropdown-container/DropdownContainer";
import { NavLogo } from "./components/logo/NavLogo";
import { MainLink } from "./components/main-link/MainLink";
import { NavContent } from "./components/nav-content/NavContent";
import { NavigationContainer } from "./components/navigation-container/NavigationContainer";
import { SearchLink } from "./components/serach-link/SearchLink";
import { Skipper } from "./components/skipper/Skipper";
import { NavToggler } from "./components/toggler/NavToggler";

export type NavProps = {
  navigation: Navigation;
  darkMode: boolean;
  greenContactBtn?: boolean;
};

export const Nav: FC<NavProps> = ({
  navigation,
  greenContactBtn,
  darkMode = false,
}) => {
  useSearchShortcut();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isInitialPos, setIsInitialPos] = useState<boolean>(true);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [rootDropdown, setRootDropdown] = useState<DropdownData | undefined>();
  const dropdowns = buildDropdownsMap(buildDropdowns(navigation));
  const openDropdowns = getOpenDropdowns(rootDropdown);
  const isAnyDropdownOpen = !isEmpty(openDropdowns);
  useOnEscape(() => setRootDropdown(undefined));

  const toggleDropdown = (id: string, isRoot: boolean) => {
    const toggled = (it: DropdownData) => ({ ...it, isOpen: !it.isOpen });
    const closed = (it: DropdownData) => ({ ...it, isOpen: false });

    const target = dropdowns.get(id);
    if (!target) return;

    if (isRoot) {
      const shouldCollapse = head(openDropdowns)?.id === id;
      setRootDropdown(shouldCollapse ? undefined : toggled(target));
    } else if (rootDropdown) {
      setRootDropdown({
        ...rootDropdown,
        children: rootDropdown.children.map((it) =>
          it.id === id ? toggled(it) : closed(it)
        ),
      });
    }
  };

  const toggleMenu = () => {
    if (mobileOpen) setRootDropdown(undefined);
    setMobileOpen(!mobileOpen);
  };

  const toggleMenuFromBackdrop: MouseEventHandler<HTMLDivElement> = (event) => {
    if ((event.target as HTMLDivElement).id === "closeToggle" && isMobile)
      toggleMenu();
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < screens.lg);
    };

    const handleInitialPosition = () => {
      const initPosBool = window.scrollY > 5;
      if (initPosBool)
        document.documentElement.style.setProperty("--nav-height", "78px");
      else document.documentElement.style.setProperty("--nav-height", "100px");
      setIsInitialPos(!initPosBool);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleInitialPosition);

    handleResize();
    handleInitialPosition();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleInitialPosition);
    };
  }, []);

  useEffect(() => {
    setScrollEnabled(!mobileOpen);

    return () => setScrollEnabled(true);
  }, [mobileOpen]);

  const wasMobile = useRef(false);
  useEffect(() => {
    if (wasMobile.current !== isMobile) {
      setRootDropdown(undefined);
      setMobileOpen(false);
    }
    wasMobile.current = isMobile;
  }, [isMobile]);

  const changeMenuColor = changeColorsLogic(
    isInitialPos,
    isAnyDropdownOpen,
    darkMode
  );

  const isWhiteText = !isMobile && changeMenuColor;

  return (
    <Skipper>
      <NavigationContainer
        isInitialPos={isInitialPos}
        isAnyDropdownOpen={isAnyDropdownOpen}
        mobileOpen={mobileOpen}
      >
        {/* Logo/Toggle Wrapper */}
        <div className="flex w-full justify-between px-8 max-lg:bg-white lg:h-auto lg:w-[calc(125px+64px)] lg:pl-0 lg:pr-16">
          <NavLogo color={isWhiteText ? "white" : undefined} />
          <div className="flex gap-2 pt-[26px] md:gap-8">
            <SearchLink isWhite={isWhiteText} className="px-3 py-2 lg:hidden" />
            <NavToggler
              opened={mobileOpen}
              id="toggleButton"
              onToggle={toggleMenu}
            />
          </div>
        </div>
        {/* END Logo/Toggle Wrapper */}

        <Backdrop
          enabled={mobileOpen}
          onClick={toggleMenuFromBackdrop}
          id="closeToggle"
        >
          <NavContent isMobile={isMobile} mobileOpen={mobileOpen}>
            <ul
              id="menu"
              role="menu"
              className="block px-7 py-2 max-lg:bg-white md:px-14 md:py-7 lg:flex lg:p-0 lg:pt-[18px]"
            >
              {navigation.nodes.map((node, idx) => (
                <MainLink
                  key={idx}
                  node={node}
                  darkMode={darkMode}
                  isInitialPos={isInitialPos}
                  changeMenuColor={changeMenuColor}
                  isAnyDropdownOpen={isAnyDropdownOpen}
                  isChildDropdownOpen={head(openDropdowns)?.id === node.id}
                  onToggle={() => toggleDropdown(node.id, true)}
                >
                  <DropdownContainer
                    isMobile={isMobile}
                    isOpen={head(openDropdowns)?.id === node.id}
                    node={node}
                  >
                    {node.layout === "columns" && (
                      <DropdownColumns
                        node={node}
                        isMobile={isMobile}
                        openDropdowns={openDropdowns}
                        onToggle={(id) => toggleDropdown(id, false)}
                      />
                    )}
                    {node.layout === "two-sided" && (
                      <DropdownColumnsAndRelated node={node} />
                    )}
                    {node.layout === "horizontal" && (
                      <DropdownColumnsDescriptive node={node} />
                    )}
                  </DropdownContainer>
                </MainLink>
              ))}
              <li
                key="search"
                role="presentation"
                // Note: "relative" is important here because if we
                // open Nav dropdown then background from the ::before element
                // will be rendered on top of the search icon
                className="relative hidden items-center pl-3 lg:flex"
              >
                <SearchLink
                  isWhite={isWhiteText}
                  className="p-1.5 transition-colors hover:text-green-accent-one"
                />
              </li>
            </ul>
            <ContactButton
              darkTheme={!isMobile && changeMenuColor}
              buttonText={navigation.contactButtonLabel || "Let's connect"}
              forceGreenColor={!isMobile && (greenContactBtn || !isInitialPos)}
            />
          </NavContent>
        </Backdrop>
      </NavigationContainer>

      {/* 👇 Backdrop for the lg+ */}
      <div
        onClick={() => setRootDropdown(undefined)}
        className={cn(
          "fixed inset-0 z-10 hidden bg-black/25",
          isAnyDropdownOpen && "lg:block"
        )}
      />
    </Skipper>
  );
};
